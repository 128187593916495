// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-wrapper {
  display: flex;
  justify-content:center;
  align-items: center;
  gap:30px;
}
.button-container.active {
  background-color: #F0F8FF; /* Alice Blue */
}
.button-container {
  display: flex;
  margin: 0px;
  align-items: center;
  height: 68px;
  background-color:white;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}


.clickable {
  cursor: pointer;
}

.content-container {
  padding: 20px;
}

.image-circle {
  width: 80px;
  height: 80px;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-circle img {
  height:100%;
  width:100%;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Solution/Q_management_System/Q_Esolution/UPARK/QESolution.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,yBAAyB,EAAE,eAAe;AAC5C;AACA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,2CAA2C;EAC3C,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;;;AAGA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,2CAA2C;EAC3C,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,UAAU;AACZ","sourcesContent":[".button-wrapper {\n  display: flex;\n  justify-content:center;\n  align-items: center;\n  gap:30px;\n}\n.button-container.active {\n  background-color: #F0F8FF; /* Alice Blue */\n}\n.button-container {\n  display: flex;\n  margin: 0px;\n  align-items: center;\n  height: 68px;\n  background-color:white;\n  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);\n  border-radius: 40px;\n  font-size: 16px;\n  border-radius: 8px;\n  cursor: pointer;\n  user-select: none;\n}\n\n\n.clickable {\n  cursor: pointer;\n}\n\n.content-container {\n  padding: 20px;\n}\n\n.image-circle {\n  width: 80px;\n  height: 80px;\n  background-color: #f9f9f9;\n  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.image-circle img {\n  height:100%;\n  width:100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
