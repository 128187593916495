// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-inner img {
    max-height: 700px; /* Adjust based on your preference */
    object-fit: cover; /* Ensures the image covers the carousel area */
    transition: transform 0.5s ease-in-out; /* Faster slide transition */
  }
  .carousel-indicatorss{
    margin-top: 40px;
    border: 2px solid red;
  }

  @media (max-width: 768px) {
    .carousel-inner img {
      max-height: 400px; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .carousel-inner img {
      max-height: 300px; /* Adjust height for very small screens */
    }
  }
  
  .carousel-item {
    transition: transform -0.2s ease-in-out !important; /* Faster slide speed */
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/Solution/PgsSolution/Banner/SolutionBanner.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB,EAAE,oCAAoC;IACvD,iBAAiB,EAAE,+CAA+C;IAClE,sCAAsC,EAAE,4BAA4B;EACtE;EACA;IACE,gBAAgB;IAChB,qBAAqB;EACvB;;EAEA;IACE;MACE,iBAAiB,EAAE,sCAAsC;IAC3D;EACF;;EAEA;IACE;MACE,iBAAiB,EAAE,yCAAyC;IAC9D;EACF;;EAEA;IACE,kDAAkD,EAAE,uBAAuB;EAC7E","sourcesContent":[".carousel-inner img {\n    max-height: 700px; /* Adjust based on your preference */\n    object-fit: cover; /* Ensures the image covers the carousel area */\n    transition: transform 0.5s ease-in-out; /* Faster slide transition */\n  }\n  .carousel-indicatorss{\n    margin-top: 40px;\n    border: 2px solid red;\n  }\n\n  @media (max-width: 768px) {\n    .carousel-inner img {\n      max-height: 400px; /* Adjust height for smaller screens */\n    }\n  }\n  \n  @media (max-width: 576px) {\n    .carousel-inner img {\n      max-height: 300px; /* Adjust height for very small screens */\n    }\n  }\n  \n  .carousel-item {\n    transition: transform -0.2s ease-in-out !important; /* Faster slide speed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
