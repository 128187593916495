import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TollEsolution.css';
import LONGreader from './../../TOLLSOLUTIONIMG/tollimg/Fleet-Management-Software 1.png';
import Mannualpay from './../../TOLLSOLUTIONIMG/tollimg/Server 2.png';
import identification from './../../TOLLSOLUTIONIMG/tollimg/identification.png';
import tariff from './../../TOLLSOLUTIONIMG/tollimg/tariff.png';

const Display = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <>
      <Container className="container">
        <Slider {...sliderSettings}>
        <div className="d-flex">
            <div className="col-6 d-flex align-items-center">
              <div>
                <h3 className="">Central Management System</h3>
                <p>
                Central Management System (CMS)  refers to an integrated solution that centralizes the control, monitoring, and administration of payment and access-related functionalities. 
                </p>
                <div className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center">
                  <div className="">
                    <img src={identification} alt="Identification" className="img-fluid" />
                  </div>
                  <p className="ml-3">
                  CMS manages devices with real-time monitoring, control, and upgrades.
                  </p>
                </div>
                
                <div className="d-flex align-items-center">
                  <div className="">
                    <img src={tariff} alt="Tariff" className="img-fluid" />
                  </div>
                  <p className="ml-3">
                  Enhanced security through monitoring and incident management.
                  </p>
                </div>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <img src={LONGreader} alt="Validator" className="img-fluid" style={{width:"50%"}}/>
            </div>
          </div>

        <div className="d-flex">
            <div className="col-6 d-flex align-items-center">
              <div>
                <h3 className="">Server</h3>
                <p>  Toll management server provide high quality plateform with that technology 
                  and redandent capabilities system plateform extensive administrative and 
                  reporting function designed to handle large tranjaction operation. </p>
                <div className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center">
                  <div className="">
                    <img src={identification} alt="Identification" className="img-fluid" />
                  </div>
                  <p className="ml-3">
                  Performed shedule task like clean DB,backup DB shedule transfer etc.
                  </p>
                </div>
                
                <div className="d-flex align-items-center">
                  <div className="">
                    <img src={tariff} alt="Tariff" className="img-fluid" />
                  </div>
                  <p className="ml-3">
                  Perform admin controll functions with third party database integration.
                  </p>
                </div>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <img src={Mannualpay} alt="Validator" className="img-fluid" style={{width:"50%"}}/>
            </div>
          </div>
          {/* Slide 1 */}
         

          {/* Slide 2 (can be customized with different content) */}
          
        </Slider>
      </Container>
    </>
  );
};

export default Display;
