import React from "react";
import Contact from "../Contactpage/ContactPage"
import Banner from "../Banner/Banner"

const KnowMain = () => { 
  return (
    <>
    <Banner/>
      <Contact/> 
    </>
  );
};

export default KnowMain;
