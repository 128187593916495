// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card {
    background-color: white;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    width:300px;
    transition: none;
    border-radius: 20px;
}

.icon-image-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.icon-image-container img {
  width: 50%;
  height:auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .product-container {
    flex-direction: column;
    gap: 1rem;
  }

  .product-card {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/Solution/SolutionProduct/SolutionProduct.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,2CAA2C;IAC3C,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE;IACE,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".product-card {\n    background-color: white;\n    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);\n    width:300px;\n    transition: none;\n    border-radius: 20px;\n}\n\n.icon-image-container {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #ffffff;\n}\n\n.icon-image-container img {\n  width: 50%;\n  height:auto;\n  object-fit: cover;\n}\n\n@media (max-width: 768px) {\n  .product-container {\n    flex-direction: column;\n    gap: 1rem;\n  }\n\n  .product-card {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
