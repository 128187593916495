// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.who-we-container {
  padding: 50px;
}

.who-we-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.who-we-left,
.who-we-right {
  flex: 1 1;
}

.who-we-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.who-we-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.who-we-button:hover {
  background-color: #0056b3;
}

.who-we-description {
  font-size: 1rem;
  color: #6c757d; /* Gray text */
  line-height: 1.6;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .who-we-section {
    flex-direction: column; /* Stack columns vertically */
    text-align: center; /* Center-align text on smaller screens */
  }

  .who-we-left,
  .who-we-right {
    margin-bottom: 20px; /* Add spacing between stacked sections */
  }

  .who-we-right {
    margin-bottom: 0; /* Remove extra margin for the last section */
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/About_us/Who_We/Who_We.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;;EAEE,SAAO;AACT;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,cAAc,EAAE,cAAc;EAC9B,gBAAgB;AAClB;;AAEA,mCAAmC;AACnC;EACE;IACE,sBAAsB,EAAE,6BAA6B;IACrD,kBAAkB,EAAE,yCAAyC;EAC/D;;EAEA;;IAEE,mBAAmB,EAAE,yCAAyC;EAChE;;EAEA;IACE,gBAAgB,EAAE,6CAA6C;EACjE;AACF","sourcesContent":[".who-we-container {\n  padding: 50px;\n}\n\n.who-we-section {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.who-we-left,\n.who-we-right {\n  flex: 1;\n}\n\n.who-we-title {\n  font-size: 1.5rem;\n  font-weight: bold;\n  margin-bottom: 1rem;\n}\n\n.who-we-button {\n  padding: 10px 20px;\n  font-size: 1rem;\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  cursor: pointer;\n  border-radius: 5px;\n}\n\n.who-we-button:hover {\n  background-color: #0056b3;\n}\n\n.who-we-description {\n  font-size: 1rem;\n  color: #6c757d; /* Gray text */\n  line-height: 1.6;\n}\n\n/* Media Query for Mobile Screens */\n@media (max-width: 768px) {\n  .who-we-section {\n    flex-direction: column; /* Stack columns vertically */\n    text-align: center; /* Center-align text on smaller screens */\n  }\n\n  .who-we-left,\n  .who-we-right {\n    margin-bottom: 20px; /* Add spacing between stacked sections */\n  }\n\n  .who-we-right {\n    margin-bottom: 0; /* Remove extra margin for the last section */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
