// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form {
    border-radius:25px !important; /* Force application */
  }
  @media (max-width: 1200px) {
   
    .contact-form {
      width: 100% !important;
      left: 0 !important;
      top: 0 !important;
      opacity: 1 !important;
      padding: 20px;
    }
  .getintouch{
    text-align: center;
  }
   
  }
  

  
  









/* .contact-us-container {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-left {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  font-size: 1rem;
}

.contact-right {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

h2 span {
  font-weight: bold;
}

.bg-primary {
  background-color: #3c647a !important;
}

.bg-white {
  background-color: #ffffff !important;
}

button {
  font-weight: bold;
}



@media (min-width: 768px) and (max-width: 992px) {
  .mobilenumber {
    display: block; /* Ensures it's treated as a block-level element */
    /* text-align: center; */
    /* justify-content: center; */
    /* border: 2px solid red; */
    /* max-width: 300px; Optional, to constrain width */
  /* } */
/* } */ 














`, "",{"version":3,"sources":["webpack://./src/Component/ContactUs/ContactUs.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B,EAAE,sBAAsB;EACvD;EACA;;IAEE;MACE,sBAAsB;MACtB,kBAAkB;MAClB,iBAAiB;MACjB,qBAAqB;MACrB,aAAa;IACf;EACF;IACE,kBAAkB;EACpB;;EAEA;;;;;;;;;;;;;;AAcF;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;sEAoCsE;IAClE,wBAAwB;IACxB,6BAA6B;IAC7B,2BAA2B;IAC3B,mDAAmD;EACrD,MAAM;AACR,MAAM","sourcesContent":[".contact-form {\n    border-radius:25px !important; /* Force application */\n  }\n  @media (max-width: 1200px) {\n   \n    .contact-form {\n      width: 100% !important;\n      left: 0 !important;\n      top: 0 !important;\n      opacity: 1 !important;\n      padding: 20px;\n    }\n  .getintouch{\n    text-align: center;\n  }\n   \n  }\n  \n\n  \n  \n\n\n\n\n\n\n\n\n\n/* .contact-us-container {\n  max-width: 1200px;\n  margin: 0 auto;\n}\n\n.contact-left {\n  border-top-left-radius: 30px;\n  border-bottom-left-radius: 30px;\n  font-size: 1rem;\n}\n\n.contact-right {\n  border-top-right-radius: 30px;\n  border-bottom-right-radius: 30px;\n}\n\nh2 span {\n  font-weight: bold;\n}\n\n.bg-primary {\n  background-color: #3c647a !important;\n}\n\n.bg-white {\n  background-color: #ffffff !important;\n}\n\nbutton {\n  font-weight: bold;\n}\n\n\n\n@media (min-width: 768px) and (max-width: 992px) {\n  .mobilenumber {\n    display: block; /* Ensures it's treated as a block-level element */\n    /* text-align: center; */\n    /* justify-content: center; */\n    /* border: 2px solid red; */\n    /* max-width: 300px; Optional, to constrain width */\n  /* } */\n/* } */ \n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
