// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-feature-container {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    max-width: 100%; /* Ensure the container doesn't exceed the viewport width */
    box-sizing: border-box;
  }
  
  .row {
    margin-left: 0;
    margin-right: 0; /* Remove row gutter margins */
  }
  
  .subfeature {
    padding: 0 10px; /* Add padding for spacing but prevent overflow */
  }
  
  .description-container {
    padding: 0 10px;
  }
  
  img {
    max-width: 100%; /* Ensure images don't cause overflow */
    height: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/ProductFeatures/FlappBarrier/Flappbarrier.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,iCAAiC;IACrD,eAAe,EAAE,2DAA2D;IAC5E,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,eAAe,EAAE,8BAA8B;EACjD;;EAEA;IACE,eAAe,EAAE,iDAAiD;EACpE;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe,EAAE,uCAAuC;IACxD,YAAY;EACd","sourcesContent":[".product-feature-container {\n    overflow-x: hidden; /* Prevent horizontal scrolling */\n    max-width: 100%; /* Ensure the container doesn't exceed the viewport width */\n    box-sizing: border-box;\n  }\n  \n  .row {\n    margin-left: 0;\n    margin-right: 0; /* Remove row gutter margins */\n  }\n  \n  .subfeature {\n    padding: 0 10px; /* Add padding for spacing but prevent overflow */\n  }\n  \n  .description-container {\n    padding: 0 10px;\n  }\n  \n  img {\n    max-width: 100%; /* Ensure images don't cause overflow */\n    height: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
