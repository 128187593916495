// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-container-wrapper {
  padding: 10px 40px;
  overflow: hidden; /* Prevents overflow and horizontal scrollbar */
}
.productstitleinpgs{
  color: linear-gradient(45deg, #1372C2, #09365C);
}
.product-card {
  margin: 0 25px;
  height: 350px; /* Set a fixed height for all cards */
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.slick-slide {
  padding: 20px 50px;
}

.slick-track {
  display: flex;
}

.product-card p {
  text-align: center;
  font-size: 14px;
  color: #333;
  flex-grow: 1;
}

.slick-prev, .slick-next {
  z-index: 1;
}

.slick-slide .slick-arrow {
  display: none;
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Solution/PgsSolution/SolutionProduct/SolutionProduct.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB,EAAE,+CAA+C;AACnE;AACA;EACE,+CAA+C;AACjD;AACA;EACE,cAAc;EACd,aAAa,EAAE,qCAAqC;EACpD,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,sBAAsB;EACtB,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".product-container-wrapper {\n  padding: 10px 40px;\n  overflow: hidden; /* Prevents overflow and horizontal scrollbar */\n}\n.productstitleinpgs{\n  color: linear-gradient(45deg, #1372C2, #09365C);\n}\n.product-card {\n  margin: 0 25px;\n  height: 350px; /* Set a fixed height for all cards */\n  width: 250px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  overflow: hidden;\n}\n\n.slick-slide {\n  padding: 20px 50px;\n}\n\n.slick-track {\n  display: flex;\n}\n\n.product-card p {\n  text-align: center;\n  font-size: 14px;\n  color: #333;\n  flex-grow: 1;\n}\n\n.slick-prev, .slick-next {\n  z-index: 1;\n}\n\n.slick-slide .slick-arrow {\n  display: none;\n}\n\n.slick-prev {\n  left: -50px;\n}\n\n.slick-next {\n  right: -50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
