// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-wrapper {
  display: flex;
  justify-content:center;
  align-items: center;
  gap:30px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color:white;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.clickable {
  cursor: pointer;
}

.content-container {
  padding: 20px;
}
.button-container.active {
  background-color: #f0f8ff;
 
}
`, "",{"version":3,"sources":["webpack://./src/Component/Solution/IotSolution/IotEsolution/APARK/IotEsolution.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,2CAA2C;EAC3C,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;;AAE3B","sourcesContent":[".button-wrapper {\n  display: flex;\n  justify-content:center;\n  align-items: center;\n  gap:30px;\n}\n\n.button-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 50px;\n  background-color:white;\n  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);\n  border-radius: 40px;\n  font-size: 16px;\n  border-radius: 8px;\n  cursor: pointer;\n  user-select: none;\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.content-container {\n  padding: 20px;\n}\n.button-container.active {\n  background-color: #f0f8ff;\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
