// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container,
.content-container {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.image-container img {
    max-width: 100%;
    height: auto;
}

.content-container {
    justify-content: flex-end;
}

.d-flex.gap-4 {
    gap: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/Component/ItServices/AIvision/SoftwareSolution/SoftwareSolution.css"],"names":[],"mappings":"AAAA;;IAEI,SAAO;IACP,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,SAAS;AACb","sourcesContent":[".image-container,\n.content-container {\n    flex: 1;\n    display: flex;\n    align-items: center;\n}\n\n.image-container img {\n    max-width: 100%;\n    height: auto;\n}\n\n.content-container {\n    justify-content: flex-end;\n}\n\n.d-flex.gap-4 {\n    gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
